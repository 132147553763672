import * as React from "react"
import * as styles from './Accordion.module.scss'
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';

const Accordion = ({qas}) => {
  var index = 0;
  return (
    <div className={styles.container}>
      {qas.map(doc => (
        <div className={styles.item}>
          <input className={styles.toggle} type="checkbox" id={`chck${index}`}/>
          <label className={styles.question} for={`chck${index++}`}>{doc.node.Question}</label>
          <div className={styles.response}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={doc.node.Answer}/>
          </div>
        </div>
      ))}
    </div>
  )
}
export default Accordion;