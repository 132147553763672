import * as React from "react"
import {graphql} from "gatsby";
import Layout from '../components/Layout/Layout';
import CenteredSection from '../components/CenteredSection/CenteredSection';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import WideImageSection from '../components/WideImageSection/WideImageSection';
import Accordion from '../components/Accordion/Accordion';

const Visit = ({data}) => {
  const article = data.allStrapiArticle.edges[0];
  return (
    <Layout pageTitle='Plan a Visit'>
      <WideImageSection image={article.node.HeaderImage.localFile} heading={article.node.HeaderText}/>
      <CenteredSection>
        <section>
          <h2>{article.node.Title}</h2>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={article.node.Content}/>
        </section>
        <section>
          <h2>Frequently Asked Questions</h2>
          <Accordion qas={data.allStrapiQAndA.edges}/>
        </section>
      </CenteredSection>
    </Layout>
  )
}
export default Visit;

export const pageQuery=graphql`
  query VisitQuery {
    allStrapiArticle(filter: {Page: {eq: "visit"}} sort: {order: ASC, fields: Index}) {
      edges {
        node {
          HeaderText
          HeaderImage {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          Title
          Content
        }
      }
    }
    allStrapiQAndA(filter: {Page: {eq: "visit"}} sort: {order: ASC, fields: Index}) {
      edges {
        node {
          Question
          Answer
        }
      }
    }
  }
`

